<template>
  <div class="p-grid p-col-12">
    <InstallationChangeSeller
      :showToast="true"
      :show="changeSeller"
      :installationId="installation.id"
      :currentSeller="installation.seller"
      v-on:cancel="changeSellerCancel"
      v-on:setInstallationSeller="changedSeller"
    />
    <InstallationChangeManager
      :showToast="true"
      :show="changeManager"
      :installationId="installation.id"
      :currentManager="installation.manager"
      v-on:cancel="changeManagerCancel"
      v-on:setInstallationManager="changedManager"
    />
    <InstallationChangeResponsible
      :showToast="true"
      :show="changeResponsible"
      :installationId="installation.id"
      :currentTechnician="installation.responsibleTechnical"
      v-on:cancel="changeResposibleCancel"
      v-on:setResponsibleInstallation="changedResposible"
    />
    <InstallationChangeTechnician
      :showToast="true"
      :show="changeTechnician"
      :installationId="installation.id"
      :currentTechnician="installation.technician"
      v-on:cancel="changeTecResposibleCancel"
      v-on:setTechnicianInstallation="changedTecResposible"
    />
    <InstallationSetPending
      :showToast="true"
      :show="statusSetPending"
      :installationId="installation.id"
      :installationName="installation.description"
      v-on:cancel="setPendingCancel"
      v-on:setPendingInstallation="setPendingOk"
    />
    <InstallationRemovePending
      :showToast="true"
      :show="statusRemovePending"
      :installationId="installation.id"
      :installationName="installation.description"
      v-on:cancel="removePendingCancel"
      v-on:removedPendingInstallation="removePendingOk"
    />
    <InstallationSetPilotDate
      :showToast="true"
      :show="statusSetPilotDate"
      :installationId="installation.id"
      :installationName="installation.description"
      v-on:cancel="setPilotDateCancel"
      v-on:addedPilotDate="setPilotDateOk"
    />
    <InstallationSetConclude
      :showToast="true"
      :show="statusSetConclude"
      :installationId="installation.id"
      :installationName="installation.description"
      v-on:cancel="setConcludeCancel"
      v-on:completedInstallation="setConcludeOk"
    />
    <InstallationSetDeliveryDate
      :showToast="true"
      :show="statusSetDeliveryDate"
      :installationId="installation.id"
      :installationName="installation.description"
      v-on:cancel="setDeliveryDateCancel"
      v-on:addedDeliveryDate="setDeliveryDateOk"
    />
    <InstallationSetReportDate
      :showToast="true"
      :show="statusSetReportDate"
      :installationId="installation.id"
      :installationName="installation.description"
      v-on:cancel="setReportDateCancel"
      v-on:addedReportDate="setReportDateOk"
    />
    <InstallationSetMarked
      :showToast="true"
      :show="statusSetMarked"
      :installationId="installation.id"
      :installationName="installation.description"
      v-on:cancel="setMarkedCancel"
      v-on:marked="setMarkedOk"
    />
    <div class="p-col-12">
      <div class="card p-shadow-6">
        <h2>
          Instalação {{ $route.params.id }} | {{ installation.description }}
        </h2>
      </div>
    </div>
    <div class="p-col-12 p-md-6">
      <div class="card p-shadow-6">
        <Accordion :activeIndex="0">
          <AccordionTab class="accordion-custom">
            <template #header>
              <div style="font-size: large">
                <i class="pi pi-info-circle p-mr-2"></i>Instalação |
                {{ installation.status }}
              </div>
            </template>
            <div class="p-grid">
              <div class="p-col-12">
                <Button
                  v-if="
                    installation.status &&
                    !installation.status.includes('Pendente') &&
                    (loggedUser.isEsa ||
                      loggedUser.isAdmin ||
                      loggedUser.username == installation.seller ||
                      loggedUser.username == installation.manager ||
                      loggedUser.username ==
                        installation.responsibleTechnical ||
                      loggedUser.username == installation.technician)
                  "
                  v-tooltip="'Alterar Estado para Pendente'"
                  label="Pendente"
                  class="p-button-sm p-mr-2"
                  @click="statusSetPending = true"
                />
                <Button
                  v-if="
                    installation.status &&
                    installation.status.includes('Pendente') &&
                    (loggedUser.isEsa ||
                      loggedUser.isAdmin ||
                      loggedUser.username == installation.seller ||
                      loggedUser.username == installation.manager ||
                      loggedUser.username ==
                        installation.responsibleTechnical ||
                      loggedUser.username == installation.technician)
                  "
                  label="Retirar de Pendente"
                  class="p-button-sm p-mr-2"
                  @click="statusRemovePending = true"
                />
                <Button
                  v-if="
                    installation.status &&
                    installation.status == 'Em Piloto Sem Data' &&
                    (loggedUser.isEsa ||
                      loggedUser.isAdmin ||
                      loggedUser.username == installation.seller ||
                      loggedUser.username == installation.manager ||
                      loggedUser.username ==
                        installation.responsibleTechnical ||
                      loggedUser.username == installation.technician)
                  "
                  label="Adicionar Data de Piloto"
                  class="p-button-sm p-mr-2"
                  @click="statusSetPilotDate = true"
                />
                <Button
                  v-if="
                    installation.status &&
                    installation.status == 'Por Concluir' &&
                    (loggedUser.isEsa || loggedUser.isAdmin)
                  "
                  label="Concluir Instalação"
                  class="p-button-sm p-mr-2"
                  @click="statusSetConclude = true"
                />
                <Button
                  v-if="
                    installation.status &&
                    installation.status == 'Sem Data de Entrega' &&
                    (loggedUser.isEsa ||
                      loggedUser.isAdmin ||
                      loggedUser.username == installation.seller ||
                      loggedUser.username == installation.manager ||
                      loggedUser.username ==
                        installation.responsibleTechnical ||
                      loggedUser.username == installation.technician)
                  "
                  label="Inserir Data de Entrega"
                  class="p-button-sm p-mr-2"
                  @click="statusSetDeliveryDate = true"
                />
                <Button
                  v-if="
                    installation.status &&
                    installation.status == 'Relatório Sem Data' &&
                    (loggedUser.isEsa ||
                      loggedUser.isAdmin ||
                      loggedUser.username == installation.seller ||
                      loggedUser.username == installation.manager ||
                      loggedUser.username ==
                        installation.responsibleTechnical ||
                      loggedUser.username == installation.technician)
                  "
                  label="Inserir Relatório"
                  class="p-button-sm p-mr-2"
                  @click="statusSetReportDate = true"
                />
                <Button
                  v-if="
                    installation.status &&
                    installation.status == 'Sem Marcação' &&
                    (loggedUser.isEsa ||
                      loggedUser.isAdmin ||
                      loggedUser.username == installation.seller ||
                      loggedUser.username == installation.manager ||
                      loggedUser.username ==
                        installation.responsibleTechnical ||
                      loggedUser.username == installation.technician)
                  "
                  label="Marcar Instalação"
                  class="p-button-sm p-mr-2"
                  @click="statusSetMarked = true"
                />
              </div>
              <div class="p-col-11">
                <label><b>Id</b></label>
                <InputText
                  type="text"
                  :value="installation.id"
                  style="width: 100%"
                  disabled
                />
              </div>
              <div class="p-col-11">
                <label><b>Nome</b></label>
                <InputText
                  type="text"
                  :value="installation.name"
                  style="width: 100%"
                  disabled
                />
              </div>
              <div class="p-col-11">
                <label><b>Descrição</b></label>
                <InputText
                  type="text"
                  :value="installation.description"
                  style="width: 100%"
                  disabled
                />
              </div>
              <div class="p-col-11">
                <label><b>Vendedor </b></label>
                <InputText
                  type="text"
                  :value="installation.seller"
                  style="width: 100%"
                  disabled
                />
              </div>
              <div
                class="p-col-1 p-mt-3 p-text-right"
                v-if="
                  loggedUser.isEsa ||
                  loggedUser.isAdmin ||
                  loggedUser.username == installation.seller
                "
              >
                <Button
                  v-tooltip.left="'Alterar Vendedor'"
                  icon="pi pi-shopping-cart"
                  class="p-button-rounded p-button-secondary p-button-outlined p-mr-2"
                  @click="changeSeller = true"
                />
              </div>
              <div class="p-col-11">
                <label><b>Gestor</b></label>
                <InputText
                  type="text"
                  :value="installation.manager"
                  style="width: 100%"
                  disabled
                />
              </div>
              <div
                class="p-col-1 p-mt-3 p-text-right"
                v-if="
                  loggedUser.isEsa ||
                  loggedUser.isAdmin ||
                  loggedUser.username == installation.manager
                "
              >
                <Button
                  v-tooltip.left="'Alterar Gestor'"
                  icon="pi pi-briefcase"
                  class="p-button-rounded p-button-secondary p-button-outlined p-mr-2"
                  @click="changeManager = true"
                />
              </div>
              <div class="p-col-11">
                <label><b>Responsável</b></label>
                <InputText
                  type="text"
                  :value="installation.responsibleTechnical"
                  style="width: 100%"
                  disabled
                />
              </div>
              <div
                class="p-col-1 p-mt-3 p-text-right"
                v-if="
                  loggedUser.isEsa ||
                  loggedUser.isAdmin ||
                  loggedUser.username == installation.responsibleTechnical
                "
              >
                <Button
                  v-tooltip.left="'Alterar Responsável'"
                  icon="pi pi-building"
                  class="p-button-rounded p-button-secondary p-button-outlined p-mr-2"
                  @click="changeResponsible = true"
                />
              </div>
              <div class="p-col-11">
                <label><b>Técnico Responsável</b></label>
                <InputText
                  type="text"
                  :value="installation.technician"
                  style="width: 100%"
                  disabled
                />
              </div>
              <div
                class="p-col-1 p-mt-3 p-text-right"
                v-if="
                  loggedUser.isEsa ||
                  loggedUser.isAdmin ||
                  loggedUser.username == installation.technician
                "
              >
                <Button
                  v-tooltip.left="'Alterar Técnico Responsável'"
                  icon="pi pi-users"
                  class="p-button-rounded p-button-secondary p-button-outlined p-mr-2"
                  @click="changeTechnician = true"
                />
              </div>
              <div class="p-col-11">
                <label><b>Data de Criação</b></label>
                <InputText
                  type="text"
                  :value="installation.creationDate"
                  style="width: 100%"
                  disabled
                />
              </div>
              <div class="p-col-11">
                <label><b>Data da Instalação</b></label>
                <InputText
                  type="text"
                  :value="
                    installation.installationDate == '1900-01-01'
                      ? ''
                      : installation.installationDate
                  "
                  style="width: 100%"
                  disabled
                />
              </div>
              <div class="p-col-11">
                <label><b>Concluido</b></label>
                <InputText
                  type="text"
                  :value="installation.concluded ? 'Sim' : 'Não'"
                  style="width: 100%"
                  disabled
                />
              </div>
              <div class="p-col-11">
                <label><b>Data da Conclusão: </b></label>
                <InputText
                  type="text"
                  :value="
                    installation.concluisionDate == '1900-01-01'
                      ? ''
                      : installation.concluisionDate
                  "
                  style="width: 100%"
                  disabled
                />
              </div>
              <div class="p-col-11" v-if="installation.hasReport">
                <label><b>Data do Relatório</b></label>
                <InputText
                  type="text"
                  :value="installation.reportDate"
                  style="width: 100%"
                  disabled
                />
              </div>
              <div
                class="p-col-1 p-mt-3 p-text-right"
                v-if="installation.hasReport"
              >
                <Button
                  v-tooltip.left="'Ver Relatório'"
                  icon="pi pi-eye"
                  class="p-button-rounded p-button-secondary p-button-outlined p-mr-2"
                  @click="goReport(installation.reportLink)"
                />
              </div>
            </div>
            <divider />
            <Button
              v-if="installation.hasInstallationSheet"
              label="Ver Folha de Instalação"
              icon="pi pi-sliders-h"
              class="p-button-sm p-button-success p-ml-1"
              @click="
                $router.push(`/task-installation-sheet/${installation.id}/1`)
              "
            />
            <Button
              v-if="installation.satisfactionForm.length > 0"
              label="Inquérito de Satisfação"
              icon="pi pi-check-circle"
              class="p-button-sm p-button-success p-ml-1"
              @click="
                $router.push(
                  `/satisfaction-form-installations-quiz/${installation.id}`
                )
              "
            />
            <divider />
            <div v-if="installation.externalManager.name != undefined">
              <h5>Gestor Externo (NOS)</h5>
              <div class="p-grid p-col-12">
                <div class="p-col-6">
                  <label><b>Nome</b></label>
                  <InputText
                    type="text"
                    :value="installation.externalManager.name"
                    style="width: 100%"
                    disabled
                  />
                </div>
                <div class="p-col-6">
                  <label><b>Função</b></label>
                  <InputText
                    type="text"
                    :value="installation.externalManager.type"
                    style="width: 100%"
                    disabled
                  />
                </div>
                <div class="p-col-6">
                  <label><b>Telefone</b></label>
                  <InputText
                    type="text"
                    :value="installation.externalManager.telephone"
                    style="width: 100%"
                    disabled
                  />
                </div>
                <div class="p-col-6">
                  <label><b>Email</b></label>
                  <InputText
                    type="text"
                    :value="installation.externalManager.email"
                    style="width: 100%"
                    disabled
                  />
                </div>
              </div>
            </div>
          </AccordionTab>
        </Accordion>
      </div>
      <div class="card p-shadow-6">
        <div>
          <Accordion>
            <AccordionTab class="accordion-custom">
              <template #header>
                <div style="font-size: large">
                  <i class="pi pi-list p-mr-2"></i>Pats
                </div>
              </template>
              <DataTable
                :value="installation.pats"
                class="p-datatable-sm"
                :sortOrder="1"
                sortField="streakIndex"
                responsiveLayout="scroll"
                sortMode="single"
                :scrollable="true"
                scrollHeight="flex"
              >
                <template #empty>
                  A instalação ainda não tem pats associados
                </template>
                <Column field="pat" header="Pat" filterField="pat">
                  <template #body="slotProps">
                    <span
                      class="clickable-item"
                      v-tooltip="'Ver Pat'"
                      @click="goPat(slotProps.data.pat)"
                      >{{ slotProps.data.pat }}</span
                    >
                  </template>
                </Column>
                <Column field="type" header="Tipo" filterField="type">
                  <template #body="slotProps">
                    {{ slotProps.data.type }}
                  </template>
                </Column>
                <Column field="problem" header="Problema" filterField="problem">
                  <template #body="slotProps">
                    {{ slotProps.data.problem }}
                  </template>
                </Column>
                <Column field="status" header="Estado" filterField="status">
                  <template #body="slotProps">
                    {{ slotProps.data.status }}
                  </template>
                </Column>
                <Column field="closed" header="Fechado" filterField="closed">
                  <template #body="slotProps">
                    {{ slotProps.data.closed == 1 ? "Sim" : "Não" }}
                  </template>
                </Column>
                <Column field="createAt" header="Data" filterField="createAt">
                  <template #body="slotProps">
                    {{ slotProps.data.createAt }}
                  </template>
                </Column>
              </DataTable>
            </AccordionTab>
          </Accordion>
        </div>
      </div>
    </div>
    <div class="p-col-12 p-md-6">
      <div class="card p-shadow-6">
        <Accordion>
          <AccordionTab class="accordion-custom">
            <template #header>
              <div style="font-size: large">
                <i class="pi pi-comment p-mr-2"></i>Comentários
              </div>
            </template>
            <PrimeVueTimeline
              :value="installation.comments"
              align="left"
              class="customized-timeline"
            >
              <template #marker>
                <span class="custom-marker p-shadow-4 p-mt-3">
                  <i :class="'pi pi-comment'"></i>
                </span>
              </template>
              <template #content="slotProps">
                <Card class="p-shadow-4 p-mt-2">
                  <template #title>
                    {{ slotProps.item.created_by }}
                  </template>
                  <template #subtitle>
                    {{ slotProps.item.created_at }}
                  </template>
                  <template #content>
                    {{ slotProps.item.comment }}
                  </template>
                </Card>
              </template>
            </PrimeVueTimeline>
            <Button
              label="Adicionar Comentários"
              icon="pi pi-comment"
              class="p-button-sm p-button-success p-mt-3"
              @click="$router.push(`/project-comments-view/${installation.id}`)"
            />
          </AccordionTab>
        </Accordion>
      </div>
      <div class="card p-shadow-6">
        <H3>Material</H3>
        <Accordion>
          <AccordionTab
            class="accordion-custom"
            v-if="installation.materialInfo.materialToOrder.length > 0"
          >
            <template #header>
              <div style="font-size: large">
                <i class="pi pi-table p-mr-2"></i>Material por Encomendar
              </div>
              <Badge
                v-badge="installation.materialInfo.materialToOrder.length"
                class="p-ml-2"
              ></Badge>
            </template>
            <div
              v-for="(material, index) in installation.materialInfo
                .materialToOrder"
              :key="index"
              class="p-grid p-col-12"
            >
              <divider v-if="index != 0" />
              <div class="p-col-3">
                <label><b>Ref.</b></label>
                <InputText
                  type="text"
                  :value="material.internal_dossier_line_reference"
                  style="width: 100%"
                  disabled
                />
              </div>
              <div class="p-col-5">
                <label><b>Designação</b></label>
                <InputText
                  type="text"
                  :value="material.internal_dossier_line_designation"
                  style="width: 100%"
                  disabled
                />
              </div>
              <div class="p-col-2">
                <label><b>Em Stock</b></label>
                <InputText
                  type="text"
                  :value="parseInt(material.stock)"
                  style="width: 100%"
                  disabled
                />
              </div>
              <div class="p-col-2">
                <label><b>Encomendar</b></label>
                <InputText
                  type="text"
                  :value="parseInt(material.quantity_to_order)"
                  style="width: 100%"
                  disabled
                />
              </div>
            </div>
          </AccordionTab>
          <AccordionTab
            class="accordion-custom"
            v-if="installation.materialInfo.materialToBeTreated.length > 0"
          >
            <template #header>
              <div style="font-size: large">
                <i class="pi pi-table p-mr-2"></i>Material por Tratar
              </div>
              <Badge
                v-badge="installation.materialInfo.materialToBeTreated.length"
                class="p-ml-2"
              ></Badge>
            </template>
            <div
              v-for="(material, index) in installation.materialInfo
                .materialToBeTreated"
              :key="index"
              class="p-grid p-col-12"
            >
              <divider v-if="index != 0" />
              <div class="p-col-3">
                <label><b>Ref.</b></label>
                <InputText
                  type="text"
                  :value="material.internal_dossier_line_reference"
                  style="width: 100%"
                  disabled
                />
              </div>
              <div class="p-col-5">
                <label><b>Designação</b></label>
                <InputText
                  type="text"
                  :value="material.internal_dossier_line_designation"
                  style="width: 100%"
                  disabled
                />
              </div>
              <div class="p-col-2">
                <label><b>Em Stock</b></label>
                <InputText
                  type="text"
                  :value="parseInt(material.stock)"
                  style="width: 100%"
                  disabled
                />
              </div>
              <div class="p-col-2">
                <label><b>Quantidade</b></label>
                <InputText
                  type="text"
                  :value="parseInt(material.internal_dossier_line_quantity)"
                  style="width: 100%"
                  disabled
                />
              </div>
            </div>
          </AccordionTab>
          <AccordionTab
            class="accordion-custom"
            v-if="installation.materialInfo.materialToBeReceived.length > 0"
          >
            <template #header>
              <div style="font-size: large">
                <i class="pi pi-table p-mr-2"></i>Material por Receber
              </div>
              <Badge
                v-badge="installation.materialInfo.materialToBeReceived.length"
                class="p-ml-2"
              ></Badge>
            </template>
            <div
              v-for="(material, index) in installation.materialInfo
                .materialToBeReceived"
              :key="index"
              class="p-grid p-col-12"
            >
              <divider v-if="index != 0" />
              <div class="p-col-3">
                <label><b>Ref.</b></label>
                <InputText
                  type="text"
                  :value="material.internal_dossier_line_reference"
                  style="width: 100%"
                  disabled
                />
              </div>
              <div class="p-col-5">
                <label><b>Designação</b></label>
                <InputText
                  type="text"
                  :value="material.internal_dossier_line_designation"
                  style="width: 100%"
                  disabled
                />
              </div>
              <div class="p-col-2">
                <label><b>Quantidade</b></label>
                <InputText
                  type="text"
                  :value="parseInt(material.internal_dossier_line_quantity)"
                  style="width: 100%"
                  disabled
                />
              </div>
              <div class="p-col-2">
                <label><b>Por Receber</b></label>
                <InputText
                  type="text"
                  :value="parseInt(material.quantity_received)"
                  style="width: 100%"
                  disabled
                />
              </div>
              <div class="p-col-4">
                <label><b>Fornecedor</b></label>
                <InputText
                  type="text"
                  :value="material.supplier"
                  style="width: 100%"
                  disabled
                />
              </div>
              <div class="p-col-4">
                <label><b>Cliente</b></label>
                <InputText
                  type="text"
                  :value="material.order_customer"
                  style="width: 100%"
                  disabled
                />
              </div>
              <div class="p-col-4">
                <label><b>Observações</b></label>
                <InputText
                  type="text"
                  :value="material.obs"
                  style="width: 100%"
                  disabled
                />
              </div>
            </div>
          </AccordionTab>
          <AccordionTab
            class="accordion-custom"
            v-if="
              installation.materialInfo.materialToBeReceivedPending.length > 0
            "
          >
            <template #header>
              <div style="font-size: large">
                <i class="pi pi-table p-mr-2"></i>Material por Receber Pendente
              </div>
              <Badge
                v-badge="
                  installation.materialInfo.materialToBeReceivedPending.length
                "
                class="p-ml-2"
              ></Badge>
            </template>
            <div
              v-for="(material, index) in installation.materialInfo
                .materialToBeReceivedPending"
              :key="index"
              class="p-grid p-col-12"
            >
              <divider v-if="index != 0" />
              <div class="p-col-3">
                <label><b>Ref.</b></label>
                <InputText
                  type="text"
                  :value="material.internal_dossier_line_reference"
                  style="width: 100%"
                  disabled
                />
              </div>
              <div class="p-col-5">
                <label><b>Designação</b></label>
                <InputText
                  type="text"
                  :value="material.internal_dossier_line_designation"
                  style="width: 100%"
                  disabled
                />
              </div>
              <div class="p-col-2">
                <label><b>Quantidade</b></label>
                <InputText
                  type="text"
                  :value="parseInt(material.internal_dossier_line_quantity)"
                  style="width: 100%"
                  disabled
                />
              </div>
              <div class="p-col-2">
                <label><b>Por Receber</b></label>
                <InputText
                  type="text"
                  :value="parseInt(material.quantity_received)"
                  style="width: 100%"
                  disabled
                />
              </div>
              <div class="p-col-4">
                <label><b>Fornecedor</b></label>
                <InputText
                  type="text"
                  :value="material.supplier"
                  style="width: 100%"
                  disabled
                />
              </div>
              <div class="p-col-4">
                <label><b>Cliente</b></label>
                <InputText
                  type="text"
                  :value="material.order_customer"
                  style="width: 100%"
                  disabled
                />
              </div>
              <div class="p-col-4">
                <label><b>Observações</b></label>
                <InputText
                  type="text"
                  :value="material.obs"
                  style="width: 100%"
                  disabled
                />
              </div>
            </div>
          </AccordionTab>
          <AccordionTab
            class="accordion-custom"
            v-if="installation.materialInfo.receivedMaterial.length > 0"
          >
            <template #header>
              <div style="font-size: large">
                <i class="pi pi-table p-mr-2"></i>Material Recebido
              </div>
              <Badge
                v-badge="installation.materialInfo.receivedMaterial.length"
                class="p-ml-2"
              ></Badge>
            </template>
            <div
              v-for="(material, index) in installation.materialInfo
                .receivedMaterial"
              :key="index"
              class="p-grid p-col-12"
            >
              <divider v-if="index != 0" />
              <div class="p-col-3">
                <label><b>Ref.</b></label>
                <InputText
                  type="text"
                  :value="material.ref"
                  style="width: 100%"
                  disabled
                />
              </div>
              <div class="p-col-5">
                <label><b>Designação</b></label>
                <InputText
                  type="text"
                  :value="material.design"
                  style="width: 100%"
                  disabled
                />
              </div>
              <div class="p-col-2">
                <label><b>Quantidade</b></label>
                <InputText
                  type="text"
                  :value="parseInt(material.quantity)"
                  style="width: 100%"
                  disabled
                />
              </div>
              <div class="p-col-2">
                <label><b>Recebido</b></label>
                <InputText
                  type="text"
                  :value="parseInt(material.quantity_received)"
                  style="width: 100%"
                  disabled
                />
              </div>
            </div>
          </AccordionTab>
        </Accordion>
      </div>
    </div>
  </div>
</template>
<script>
import PrimeVueTimeline from "primevue/timeline";
import installationservice from "../services/installations.service";
import InstallationChangeSeller from "../components/installations/ChangeSeller.vue";
import InstallationChangeManager from "../components/installations/ChangeManager.vue";
import InstallationChangeResponsible from "../components/installations/ChangeResponsible.vue";
import InstallationChangeTechnician from "../components/installations/ChangeTechnician.vue";
import InstallationSetPending from "../components/installations/SetPending.vue";
import InstallationRemovePending from "../components/installations/RemovePending.vue";
import InstallationSetPilotDate from "../components/installations/SetPilotDate.vue";
import InstallationSetConclude from "../components/installations/SetConclude.vue";
import InstallationSetDeliveryDate from "../components/installations/SetDeliveryDate.vue";
import InstallationSetReportDate from "../components/installations/SetReportDate.vue";
import InstallationSetMarked from "../components/installations/SetMarked.vue";

export default {
  name: "InstallationsDetails",
  components: {
    PrimeVueTimeline,
    InstallationChangeSeller,
    InstallationChangeManager,
    InstallationChangeResponsible,
    InstallationChangeTechnician,
    InstallationSetPending,
    InstallationRemovePending,
    InstallationSetPilotDate,
    InstallationSetConclude,
    InstallationSetDeliveryDate,
    InstallationSetReportDate,
    InstallationSetMarked,
  },
  data() {
    return {
      loading: true,
      installation: {
        comments: [],
        satisfactionForm: [],
        externalManager: {},
        pats: [],
        materialInfo: {
          materialToBeReceived: [],
          materialToBeReceivedPending: [],
          materialToBeTreated: [],
          materialToOrder: [],
          receivedMaterial: [],
        },
      },
      changeSeller: false,
      changeManager: false,
      changeResponsible: false,
      changeTechnician: false,
      statusSetPending: false,
      statusRemovePending: false,
      statusSetPilotDate: false,
      statusSetConclude: false,
      statusSetDeliveryDate: false,
      statusSetReportDate: false,
      statusSetMarked: false,
    };
  },
  computed: {
    loggedUser() {
      return this.$store.state.auth.user;
    },
  },
  async created() {
    this.loading = true;
    this.installation = await installationservice.getInstallation(
      this.$route.params.id
    );
    this.loading = false;
  },
  methods: {
    goPat(id) {
      let route = this.$router.resolve({
        path: `/pat-details/${id}`,
      });
      window.open(route.href, "_blank");
    },
    changeSellerCancel() {
      return (this.changeSeller = false);
    },
    changedSeller(response) {
      if (response.status == "ok") {
        this.installation.seller = response.currenTechnican;
      }
      return this.changeSellerCancel();
    },
    changeManagerCancel() {
      return (this.changeManager = false);
    },
    changedManager(response) {
      if (response.status == "ok") {
        this.installation.manager = response.currenTechnican;
      }
      return this.changeManagerCancel();
    },
    changeResposibleCancel() {
      return (this.changeResponsible = false);
    },
    changedResposible(response) {
      if (response.status == "ok") {
        this.installation.responsibleTechnical = response.currenTechnican;
      }
      return this.changeResposibleCancel();
    },
    changeTecResposibleCancel() {
      return (this.changeTechnician = false);
    },
    changedTecResposible(response) {
      if (response.status == "ok") {
        this.installation.technician = response.currenTechnican;
      }
      return this.changeTecResposibleCancel();
    },
    goReport(url) {
      const pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR IP (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$", // fragment locator
        "i"
      );
      if (pattern.test(url)) {
        return window.open(url, "_blank");
      } else {
        return this.$toast.add({
          severity: "error",
          summary: "Link Iválido",
          detail: "O link do relatório não é válido",
          life: 3000,
        });
      }
    },
    setPendingCancel() {
      return (this.statusSetPending = false);
    },
    setPendingOk(response) {
      if (response.status == "ok") {
        this.installation.status = response.currenStatus;
      }
      return this.setPendingCancel();
    },
    removePendingCancel() {
      return (this.statusRemovePending = false);
    },
    removePendingOk(response) {
      if (response.status == "ok") {
        this.installation.status = response.currenStatus;
      }
      return this.removePendingCancel();
    },
    setPilotDateCancel() {
      return (this.statusSetPilotDate = false);
    },
    setPilotDateOk(response) {
      if (response.status == "ok") {
        this.installation.status = response.currenStatus;
      }
      return this.setPilotDateCancel();
    },
    setConcludeCancel() {
      return (this.statusSetConclude = false);
    },
    setConcludeOk(response) {
      if (response.status == "ok") {
        this.installation.status = response.currenStatus;
      }
      return this.setConcludeCancel();
    },
    setDeliveryDateCancel() {
      return (this.statusSetDeliveryDate = false);
    },
    setDeliveryDateOk(response) {
      if (response.status == "ok") {
        this.installation.status = response.currenStatus;
      }
      return this.setDeliveryDateCancel();
    },
    setReportDateCancel() {
      return (this.statusSetReportDate = false);
    },
    setReportDateOk(response) {
      if (response.status == "ok") {
        this.installation.status = response.currenStatus;
      }
      return this.setReportDateCancel();
    },
    setMarkedCancel() {
      return (this.statusSetMarked = false);
    },
    setMarkedOk(response) {
      if (response.status == "ok") {
        this.installation.status = response.currenStatus;
      }
      return this.setMarkedCancel();
    },
  },
};
</script>
<style>
.clickable-item {
  cursor: pointer;
  color: #000;
  text-decoration-line: underline;
  text-decoration-style: dashed;
}
.p-accordion-header-link {
  background-color: #474a54 !important;
  color: #fff !important;
}
</style>
